<template>
  <v-dialog
    v-model="isAddSendBodySick"
    persistent
    max-width="500"
  >
    <v-card>
      <v-card-title>
        {{ $t("confirmation_of_the_pharmacist_referral_form") }}
      </v-card-title>
      <v-card-text>
        <v-textarea
          v-model="phrf_comment"
          :label="$t('found_problems')"
          outlined
          hide-details="auto"
          rows="3"
        >
        </v-textarea>
        <v-checkbox
          v-model="phrf_basic_id"
          hide-details
          true-value="1"
          false-value="0"
          :label="$t('providing_initial_treatment_including')"
        ></v-checkbox>
        <v-textarea
          v-model="phrf_basic"
          hide-details
          outlined
          rows="3"
          :disabled="phrf_basic_id == '0'"
        ></v-textarea>
        <v-checkbox
          v-model="phrf_suggest_id"
          true-value="1"
          false-value="0"
          hide-details
          :label="$t('give_advice_on_medication')"
        ></v-checkbox>
        <v-textarea
          v-model="phrf_suggest"
          hide-details
          outlined
          rows="3"
          :disabled="phrf_suggest_id == '0'"
        ></v-textarea>
        <v-checkbox
          v-model="phrf_note_id"
          true-value="1"
          false-value="0"
          hide-details
          class="set-font-kanit"
          :label="$t('other')"
        ></v-checkbox>
        <v-textarea
          v-model="phrf_note"
          outlined
          rows="3"
          :disabled="phrf_note_id == '0'"
        ></v-textarea>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          :loading="loading"
          :disabled="loading"
          @click="addSendBodySick"
        >
          {{ $t("save") }}
        </v-btn>
        <v-btn
          color="error"
          outlined
          @click="$emit('update:isAddSendBodySick', false)"
        >
          {{ $t("cancel") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import { i18n } from '@/plugins/i18n'
import check from '@/api/queue/check'

export default {
  model: {
    prop: 'isAddSendBodySick',
    event: 'update:isAddSendBodySick',
  },
  props: {
    isAddSendBodySick: { type: Boolean, default: false },
    opdId: { type: String, default: '' },
  },
  setup(props, { emit }) {
    const phrf_comment = ref('')
    const phrf_basic = ref('')
    const phrf_suggest = ref('')
    const phrf_note = ref('')
    const phrf_basic_id = ref('0')
    const phrf_suggest_id = ref('0')
    const phrf_note_id = ref('0')
    const loading = ref(false)

    const addSendBodySick = () => {
      loading.value = true
      check.checkAddSendBodySick({
        opd_id: props.opdId,
        phrf_comment: phrf_comment.value,
        phrf_basic_id: phrf_basic_id.value,
        phrf_basic: phrf_basic.value,
        phrf_suggest_id: phrf_suggest_id.value,
        phrf_suggest: phrf_suggest.value,
        phrf_note_id: phrf_note_id.value,
        phrf_note: phrf_note.value,
      }).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        loading.value = false
        const routeData = router.resolve({
          name: 'printSendBodySick',
          query: {
            id: String([res.data]),
          },
        })
        window.open(routeData.href, '_blank')
        emit('onAdd', res.data)
        emit('update:isAddSendBodySick', false)
      })
    }

    watch(() => props.isAddSendBodySick, value => {
      if (value) {
        phrf_comment.value = ''
        phrf_basic.value = ''
        phrf_suggest.value = ''
        phrf_note.value = ''
        phrf_basic_id.value = '0'
        phrf_suggest_id.value = '0'
        phrf_note_id.value = '0'
      }
    })

    return {
      phrf_comment,
      phrf_basic,
      phrf_suggest,
      phrf_note,
      phrf_basic_id,
      phrf_suggest_id,
      phrf_note_id,
      loading,
      addSendBodySick,
    }
  },
}
</script>
