var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"min-height":"595","loading":_vm.loading}},[_c('v-card-title',{staticClass:"py-3"},[_vm._v(" "+_vm._s(_vm.opdData.opd_code)+" "),(_vm.opdData.opd_status_id=='0')?_c('span',{staticClass:"error--text ml-2"},[_vm._v("("+_vm._s(_vm.$t('cancel'))+")")]):_vm._e(),_c('span',{staticClass:"error--text text-sm pt-1 ml-2"},[_vm._v(" "+_vm._s(_vm.$t('upload_up_to_12_images'))+" ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","loading":_vm.uploadLoading,"disabled":_vm.fileFaceCartList.length >=12||_vm.uploadLoading||_vm.opdData.opd_status_id=='0'},on:{"click":function($event){return _vm.$refs.refInputEl2.click()}}},[_vm._v(" "+_vm._s(_vm.$t('upload_doc'))+" ")]),_c('input',{ref:"refInputEl2",attrs:{"type":"file","multiple":"","accept":".pdf,.jpeg,.png,.jpg","hidden":true},on:{"change":_vm.uploadFiles}})],1),_c('v-divider',{staticClass:"mt-0"}),_c('v-card-text',[(!_vm.fileFaceCartList.length)?_c('div',{attrs:{"align":"center"}},[_c('p',[_vm._v(" "+_vm._s(_vm.$t('no_information'))+" ")])]):_vm._e(),(_vm.opdData.opd_status_id != '0')?_c('draggable',{staticClass:"row mb-2",attrs:{"handle":".handle"},on:{"end":_vm.updateSort},model:{value:(_vm.fileFaceCartList),callback:function ($$v) {_vm.fileFaceCartList=$$v},expression:"fileFaceCartList"}},_vm._l((_vm.fileFaceCartList),function(item,index){return _c('v-col',{key:index,attrs:{"cols":"12","sm":"6","md":"3","lg":"2"}},[_c('v-card',[_c('v-img',{staticClass:"elevation-2 handle",staticStyle:{"cursor":"all-scroll"},attrs:{"height":"200","src":item.image}}),_c('v-card-actions',{staticClass:"mt-5"},[_c('v-spacer'),_c('v-tooltip',{attrs:{"color":"#212121","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"color":"warning","small":"","outlined":"","icon":""},on:{"click":function($event){return _vm.downloadImage(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiCloudDownloadOutline)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("download")))])]),_c('v-tooltip',{attrs:{"color":"#212121","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"color":"primary","small":"","icon":"","outlined":""},on:{"click":function($event){return _vm.previewImage(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiImageSearchOutline)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("detail")))])]),_c('v-tooltip',{attrs:{"color":"#212121","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"color":"error","small":"","icon":"","outlined":""},on:{"click":function($event){_vm.isUserConfirm = true ; _vm.fileDelete = item}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiDeleteOutline)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("delete")))])]),_c('v-spacer')],1)],1)],1)}),1):_vm._e()],1)],1),_c('UserConfirm',{on:{"onSend":_vm.removeImage},model:{value:(_vm.isUserConfirm),callback:function ($$v) {_vm.isUserConfirm=$$v},expression:"isUserConfirm"}}),_c('v-dialog',{attrs:{"max-width":"500"},model:{value:(_vm.isShowImage),callback:function ($$v) {_vm.isShowImage=$$v},expression:"isShowImage"}},[_c('v-card',[(_vm.isPdf)?_c('pdf',{attrs:{"src":_vm.urlDetailImage}}):_c('v-img',{attrs:{"src":_vm.urlDetailImage,"contain":""}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }