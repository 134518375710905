<template>
  <v-dialog
    v-model="isAddCertSick"
    persistent
    max-width="500"
  >
    <v-card>
      <v-card-title>
        {{ $t("confirmation_of_the_medical_certificate_for_leave") }}
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col
            cols="12"
            md="6"
            class="pb-0"
          >
            <v-dialog
              ref="datePicker1"
              v-model.trim="isShowDate1"
              :return-value.sync="sick_start"
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model.trim="sick_start"
                  outlined
                  hide-details
                  dense
                  :label="$t('should_be_rested_since')"
                  :disabled="disallow_sick == '1'"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model.trim="sick_start"
                class=""
                :locale="$i18n.locale"
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.datePicker1.save(sick_start)"
                >
                  {{ $t("confirm") }}
                </v-btn>
                <v-btn
                  text
                  color="secondary"
                  @click="isShowDate1 = false"
                >
                  {{ $t("cancel") }}
                </v-btn>

                <v-spacer></v-spacer>
              </v-date-picker>
            </v-dialog>
          </v-col>
          <v-col
            cols="12"
            md="6"
            class="pb-0"
          >
            <v-dialog
              ref="datePicker2"
              v-model.trim="isShowDate2"
              hide-details
              :return-value.sync="sick_end"
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model.trim="sick_end"
                  outlined
                  hide-details
                  :disabled="disallow_sick == '1'"
                  dense
                  :label="$t('to')"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model.trim="sick_end"
                class=""
                :locale="$i18n.locale"
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.datePicker2.save(sick_end)"
                >
                  {{ $t("confirm") }}
                </v-btn>
                <v-btn
                  text
                  color="secondary"
                  @click="isShowDate2 = false"
                >
                  {{ $t("cancel") }}
                </v-btn>
                <v-spacer></v-spacer>
              </v-date-picker>
            </v-dialog>
          </v-col>
          <v-col
            cols="12"
            class="pt-0"
          >
            <v-checkbox
              v-model="disallow_sick"
              true-value="1"
              false-value="0"
              hide-details
              class="pt-0 my-3"
              :label="$t('inappropriate')"
            ></v-checkbox>
            <v-textarea
              v-model="sick_symptom"
              outlined
              :label="$t('symptoms')"
              rows="3"
            ></v-textarea>
            <v-textarea
              v-model="sick_diagnose"
              hide-details
              :label="$t('diagnose')"
              outlined
              rows="3"
            ></v-textarea>
            <v-radio-group
              v-model="sick_air_id"
              mandatory
            >
              <v-radio
                :label="$t('unknown_board')"
                value="0"
              ></v-radio>
              <v-radio
                :label="$t('can_board')"
                value="1"
              ></v-radio>
              <v-radio
                :label="$t('unable_board')"
                value="2"
              ></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          :loading="loading"
          :disabled="loading"
          @click="addCertSick"
        >
          {{ $t("confirm") }}
        </v-btn>
        <v-btn
          color="error"
          outlined
          @click="$emit('update:isAddCertSick',false)"
        >
          {{ $t("cancel") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import check from '@/api/queue/check'
import store from '@/store'

export default {
  model: {
    prop: 'isAddCertSick',
    event: 'update:isAddCertSick',
  },
  props: ['isAddCertSick', 'opdId'],
  setup(props, { emit }) {
    const moment = require('moment')
    const isShowDate1 = ref(false)
    const isShowDate2 = ref(false)
    const sick_start = ref(moment(new Date()).format('YYYY-MM-DD'))
    const sick_end = ref(moment(new Date()).format('YYYY-MM-DD'))
    const disallow_sick = ref('0')
    const sick_symptom = ref('')
    const sick_diagnose = ref('')
    const sick_air_id = ref('0')
    const loading = ref(false)

    const addCertSick = () => {
      loading.value = true
      check.checkAddCertSick({
        opd_id: props.opdId,
        sick_symptom: sick_symptom.value,
        sick_diagnose: sick_diagnose.value,
        disallow_sick: disallow_sick.value,
        sick_start: sick_start.value,
        sick_end: sick_end.value,
        sick_air_id: sick_air_id.value,
      }).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        loading.value = false
        emit('onAdd', res.data)
        emit('update:isAddCertSick', false)
      })
    }

    watch(disallow_sick, value => {
      if (value == '1') {
        sick_start.value = ''
        sick_end.value = ''
      } else {
        sick_start.value = moment(new Date()).format('YYYY-MM-DD')
        sick_end.value = moment(new Date()).format('YYYY-MM-DD')
      }
    })

    watch(() => props.isAddCertSick, value => {
      if (value) {
        sick_start.value = moment(new Date()).format('YYYY-MM-DD')
        sick_end.value = moment(new Date()).format('YYYY-MM-DD')
        sick_symptom.value = ''
        sick_diagnose.value = ''
        sick_air_id.value = '0'
        disallow_sick.value = '0'
      }
    })

    return {
      moment,
      isShowDate1,
      isShowDate2,
      sick_start,
      sick_end,
      disallow_sick,
      sick_symptom,
      sick_diagnose,
      sick_air_id,
      loading,
      addCertSick,
    }
  },
}
</script>
