<template>
  <v-card min-height="595">
    <v-card-title>
      <span>{{ opdData.opd_code }}</span> <span
        v-if="opdData.opd_status_id=='0'"
        class="error--text ml-2"
      >({{ $t('cancel') }})</span> <v-spacer></v-spacer>
      <span class="d-none d-md-block"> {{ opdData.room_name }}</span>
    </v-card-title>
    <v-divider class="mt-0"></v-divider>
    <v-card-text>
      <p class=" font-weight-semibold mb-1">
        {{ $t("general_information") }} ({{ $t("history_washing_by") }} : {{ opdData.user_fullname }})
      </p>
      <v-divider
        class="mt-0 mb-2"
      ></v-divider>
      <v-row>
        <v-col
          cols="6"
          lg="3"
        >
          <span> T : </span>{{ opdData.opd_t }} C.
        </v-col>
        <v-col
          cols="6"
          lg="3"
        >
          <span>PR : </span>{{ opdData.opd_p }} /min.
        </v-col>
        <v-col
          cols="6"
          lg="3"
        >
          <span>RR : </span>{{ opdData.opd_rr }} /min.
        </v-col>
        <v-col
          cols="6"
          lg="3"
        >
          <span>BP : </span>{{ opdData.opd_bp }} mmHg.
        </v-col>
        <v-col
          cols="6"
          lg="3"
        >
          <span>{{ $t("weight") }} : </span>{{ opdData.opd_w }} {{ $t("kg") }}
        </v-col>
        <v-col
          cols="6"
          lg="3"
        >
          <span>{{ $t("height") }} : </span>{{ opdData.opd_h }} {{ $t("cm") }}
        </v-col>
        <v-col
          cols="6"
          lg="3"
        >
          <span>BMI : </span>{{ opdData.opd_bmi }}
        </v-col>
        <v-col
          cols="6"
          lg="3"
        >
          <span>FBS : </span>{{ opdData.opd_fbs }} mg/dl
        </v-col>
      </v-row>
      <p class="font-weight-semibold mt-3 mb-1">
        {{ $t("important_symptoms_current_history") }}
      </p>
      <v-divider class="mb-2"></v-divider>
      <v-row>
        <v-col
          cols="12"
          md="6"
          lg="4"
        >
          <span>CC : </span>{{ opdData.opd_cc }}
        </v-col>
        <v-col
          cols="12"
          md="6"
          lg="4"
        >
          <span>HPI : </span>{{ opdData.opd_hpi }}
        </v-col>
        <v-col
          cols="12"
          md="6"
          lg="4"
        >
          <span>PMH : </span>{{ opdData.opd_pmh }}
        </v-col>
        <v-col
          cols="12"
          md="6"
          lg="4"
        >
          <!-- <span>{{ $t('smoking') }} : </span>{{ $i18n.locale == 'en' ?smokingList[opdData.opd_fag-1].en :smokingList[opdData.opd_fag-1].th }} -->
        </v-col>
        <v-col
          cols="12"
          md="6"
          lg="4"
        >
          <!-- <span>{{ $t('drink_alcohol') }} : </span>{{ $i18n.locale == 'en' ?alcoholList[opdData.opd_alcohol-1].en :alcoholList[opdData.opd_alcohol-1].th }} -->
        </v-col>
      </v-row>
      <p class="font-weight-semibold mt-3 mb-1">
        {{ $t("physical_examination") }}
      </p>
      <v-divider class="mb-2"></v-divider>
      <v-row>
        <v-col
          cols="12"
          lg="6"
        >
          <span>PE : </span>{{ opdData.opd_pe }}
        </v-col>
        <v-col
          cols="12"
          lg="6"
        >
          <span>GA : </span>{{ opdData.opd_ga }}
        </v-col>
      </v-row>
      <p class="font-weight-semibold mt-3 mb-1">
        {{ $t("diagnosis") }}
      </p>
      <v-divider class="mb-2"></v-divider>
      <v-row>
        <v-col
          cols="12"
        >
          <span>DX : </span>{{ opdData.opd_dx }}
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
        >
          <p class="font-weight-semibold">
            {{ $t("note") }} :
          </p>
          <span v-html="opdData.opd_comment"></span>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import booze_data from '@/@fake-db/data/booze_data.json'
import smoking_data from '@/@fake-db/data/smoking_data.json'
import { ref } from '@vue/composition-api'

export default {
  props: {
    opdData: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    const smokingList = ref(smoking_data.data)
    const alcoholList = ref(booze_data.data)

    return {
      smokingList,
      alcoholList,
    }
  },
}
</script>
