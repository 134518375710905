import customer from '@/api/customer/customer'
import checkDetail from '@/api/queue/checkDetail'
import router from '@/router'
import shop from '@/api/shop'
import { useRouter } from '@core/utils'
import { mdiFileOutline, mdiFormatListBulleted, mdiImage, mdiImageOutline, mdiStethoscope } from '@mdi/js'
import { ref, onMounted } from '@vue/composition-api'

export default function useDetailChecking() {
  const opdData = ref({})
  const { route } = useRouter()
  const getCheckingLoading = ref(true)
  const getCustomerLoading = ref(true)
  const customerData = ref({})
  const showDialogCancel = ref(false)
  const profile = ref(false)
  const isShowFaceCart = ref(false)
  const idCancel = ref('')
  const isCancel = ref(false)
  const isAddCertDoctor = ref(false)
  const isAddSendBodySick = ref(false)
  const isAddCertSick = ref(false)
  const isPrintCertDoctorOption = ref(false)
  const certDoctorIdList = ref([])
  const isPrintCertSickOption = ref(false)
  const certSickIdList = ref([])

  const sendBodySickIdList = ref([])

  const tabs = ref([
    { icon: mdiStethoscope, title: 'basic_information_examination' },
    { icon: mdiFormatListBulleted, title: 'examination_list' },
    { icon: mdiImage, title: 'picture_before' },
    { icon: mdiImageOutline, title: 'picture_after' },
    { icon: mdiFileOutline, title: 'show_face_opd' },
  ])
  const opdTab = ref(null)

  const { checkingGet } = checkDetail
  const { customerById } = customer

  const { shop: shopGet } = shop
  const statusShopX = ref(null)

  onMounted(async () => {
    getShopData()
  })

  const getShopData = async () => {
    try {
      const response = await shopGet()
      statusShopX.value = response.shop_x
    } catch (error) {
      console.log(error)
    }
  }

  checkingGet(router.currentRoute.params.id).then(res => {
    opdData.value = res
    getCheckingLoading.value = false
    getCustomer(res.customer_id_pri)
  })

  const getChecking = () => {
    checkingGet(router.currentRoute.params.id).then(res => {
      opdData.value = res
      getCheckingLoading.value = false
      getCustomer(res.customer_id_pri)
    })
  }

  const printCertDoctor = () => {
    certDoctorIdList.value = []
    certDoctorIdList.value.push(router.currentRoute.params.id)
    isPrintCertDoctorOption.value = true
    getChecking()
  }

  const printCertSick = () => {
    certSickIdList.value = []
    certSickIdList.value.push(router.currentRoute.params.id)
    isPrintCertSickOption.value = true
    getChecking()
  }

  const printSendBodySick = message => {
    if (message) {
      sendBodySickIdList.value = []
      sendBodySickIdList.value = [message]
    }
    getChecking()
    const routeData = router.resolve({
      name: 'printSendBodySick',
      query: {
        id: String(sendBodySickIdList.value),
      },
    })
    window.open(routeData.href, '_blank')
  }

  const getCustomer = id => {
    customerById(id).then(res => {
      customerData.value = res
      getCustomerLoading.value = false
    })
  }

  const createReceipt = (id, date) => {
    router.push({
      name: 'creare-order-opd',
      params: { id },
      query: { date },
    })
  }

  const printMedical = () => {
    opdData.value.statusPrint = 1
  }
  const printCertificate = () => {
    opdData.value.statusPrint = 1
  }
  const printPatient = () => {
    opdData.value.statusPrint = 1
  }

  return {
    opdData,
    getCheckingLoading,
    getCustomerLoading,
    customerData,
    tabs,
    opdTab,
    profile,
    isShowFaceCart,
    createReceipt,
    printMedical,
    printCertificate,
    printPatient,
    showDialogCancel,
    idCancel,
    isCancel,
    getChecking,
    isAddCertDoctor,
    isAddSendBodySick,
    isAddCertSick,
    isPrintCertDoctorOption,
    certDoctorIdList,
    isPrintCertSickOption,
    statusShopX,
    certSickIdList,
    printCertSick,
    printCertDoctor,
    printSendBodySick,
  }
}
