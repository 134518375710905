const AWS = require('aws-sdk')

let s3 = null
function s3Config() {
  const serverS3 = {
    apiVersion: '2006-03-01',
    accessKeyId: 'AKIAVMEOMSKV6H7WMF5S',
    secretAccessKey: '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',
    region: 'ap-southeast-1',
  }
  s3 = new AWS.S3(serverS3)
  AWS.config.update(serverS3)

  return s3
}
module.exports = s3Config
