<template>
  <v-dialog
    v-model="isPrintCertDoctorOption"
    persistent
    max-width="600"
  >
    <v-card>
      <v-card-title>
        {{ $t("print_medical_certificate") }}
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <span class="font-weight-semibold">{{ $t("type") }}</span>
            <v-radio-group
              v-model="typeSelect"
              class="mt-0"
              hide-details
              mandatory
            >
              <v-radio
                :label="$t('certificate_five_diseases')"
                value="1"
              ></v-radio>
              <v-radio
                :label="$t('for_driving_license')"
                value="2"
              ></v-radio>
              <v-radio
                :label="$t('medical_certificate') + ' COVID'"
                value="3"
              ></v-radio>
            </v-radio-group>
          </v-col>
          <v-divider vertical></v-divider>
          <v-col
            cols="12"
            md="6"
          >
            <span class="font-weight-semibold">{{ $t("choose_language") }}</span>
            <v-radio-group
              v-model="langeSelect"
              hide-details
              class="mt-0"
              mandatory
            >
              <v-radio
                label="ไทย"
                value="1"
              ></v-radio>
              <v-radio
                v-if="typeSelect!=3"
                label="English"
                value="2"
              ></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          @click="print"
        >
          {{ $t("confirm") }}
        </v-btn>
        <v-btn
          outlined
          color="secondary"
          @click="$emit('update:isPrintCertDoctorOption')"
        >
          {{ $t("cancel") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import router from '@/router'

export default {
  model: {
    prop: 'isPrintCertDoctorOption',
    event: 'update:isPrintCertDoctorOption',
  },
  props: {
    isPrintCertDoctorOption: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const typeSelect = ref('1')
    const langeSelect = ref('1')

    watch(() => props.isPrintCertDoctorOption, val => {
      if (val) {
        typeSelect.value = '1'
        langeSelect.value = '1'
      }
    })

    const print = () => {
      let name = ''
      if (typeSelect.value == '1') {
        if (langeSelect.value == '2') {
          name = 'printCertDoctorEn'
        } else {
          name = 'printCertDoctor'
        }
      } else if (typeSelect.value == '2') {
        if (langeSelect.value == '2') {
          name = 'printCertDoctorDriveEn'
        } else {
          name = 'printCertDoctorDrive'
        }
      } else if (typeSelect.value == '3') {
        name = 'printCertDoctorCovid'
      }
      const routeData = router.resolve({
        name,
        query: {
          id: String(props.id),
          lang: langeSelect.value,
          type: props.type,
        },
      })
      window.open(routeData.href, '_blank')
    }

    return {
      typeSelect,
      langeSelect,
      print,
    }
  },
}
</script>
