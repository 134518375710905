import { i18n } from '@/plugins/i18n'
import refreshToken from '@/plugins/refresh-token'
import { api } from '../main'

const checkingGet = async id => {
  //await refreshToken()
  const response = await api
    .get({
      path: 'check/opdchecking',
      param: `${id}/${i18n.locale}`,
    })
    .then(res => (res.data || {}))
    .catch(err => {
      console.error('get checking data err : ', err)

      return {}
    })

  return response
}

const imageBeforeList = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'check/imagebeforelist',
      body,
    })
    .then(res => (res.data || []))
    .catch(err => {
      console.error('get image before list err : ', err)

      return []
    })

  return response
}
const imageBeforeAdd = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'check/image',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.error('add image before list err : ', err)

      return {}
    })

  return response
}
const imageBeforeRemove = async (path, param) => {
  ////await refreshToken()
  const response = await api
    .delete({
      path,
      param,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.error('add image before list err : ', err)

      return {}
    })

  return response
}
const imageBeforeSort = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'check/sortimagefile',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.error('sort image before list err : ', err)

      return {}
    })

  return response
}

/// //
const imageAfterList = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'check/imageafterlist',
      body,
    })
    .then(res => (res.data || []))
    .catch(err => {
      console.error('get image after list err : ', err)

      return []
    })

  return response
}
const imageAfterAdd = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'check/image',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.error('add image after list err : ', err)

      return {}
    })

  return response
}
const imageAfterRemove = async (path, param) => {
  ////await refreshToken()
  const response = await api
    .delete({
      path,
      param,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.error('add image after list err : ', err)

      return {}
    })

  return response
}
const imageAfterSort = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'check/sortimagefile',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.error('sort image after list err : ', err)

      return {}
    })

  return response
}

const faceCartList = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'check/fileslist',
      body,
    })
    .then(res => (res.data || []))
    .catch(err => {
      console.error('get face cart list err : ', err)

      return []
    })

  return response
}

const faceCartAdd = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'check/file',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.error('add face cart err : ', err)

      return {}
    })

  return response
}

const faceCartRemove = async (path, param) => {
  ////await refreshToken()
  const response = await api
    .delete({
      path,
      param,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.error('add image after list err : ', err)

      return {}
    })

  return response
}

const faceCartSort = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'check/sortimagefile',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.error('sort image after list err : ', err)

      return {}
    })

  return response
}

const labHistoryLasted = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'check/lablist',
      body,
    })
    .then(res => (res.data || {}))
    .catch(err => {
      console.error('get history lab list err : ', err)

      return {}
    })

  return response
}

export default {
  checkingGet,
  imageBeforeList,
  imageBeforeSort,
  imageBeforeAdd,
  imageBeforeRemove,

  imageAfterList,
  imageAfterSort,
  imageAfterAdd,
  imageAfterRemove,

  faceCartList,
  faceCartAdd,
  faceCartRemove,
  faceCartSort,

  labHistoryLasted,

}
