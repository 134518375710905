import refreshToken from '@/plugins/refresh-token'
import { api } from './main'

const onCancel = async (path, body) => {
  //await refreshToken()
  const response = await api
    .put({
      path,
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.erro('cancel data : ', err)

      return {}
    })

  return response
}
export default { onCancel }
