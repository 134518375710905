<template>
  <v-dialog
    v-model="isAddCertDoctor"
    persistent
    max-width="500"
  >
    <v-card>
      <v-card-title>
        {{ $t("confirmation_of_the_medical_certificate") }}
      </v-card-title>
      <v-card-text>
        <v-textarea
          v-model="cert_comment"
          outlined
          rows="4"
          :label="$t('summary_of_opinions')"
        ></v-textarea>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          :loading="loading"
          :disabled="loading"
          @click="addCertDoctor"
        >
          {{ $t("save") }}
        </v-btn>
        <v-btn
          color="error"
          outlined
          @click="$emit('update:isAddCertDoctor', false)"
        >
          {{ $t("cancel") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import check from '@/api/queue/check'
import store from '@/store'

export default {
  model: {
    prop: 'isAddCertDoctor',
    event: 'update:isAddCertDoctor',
  },
  props: {
    isAddCertDoctor: {
      type: Boolean,
      default: false,
    },
    opdId: {
      type: String,
      default: '',
    },
  },

  setup(props, { emit }) {
    const cert_comment = ref('สุขภาพสมบูรณ์แข็งแรงดี')
    const loading = ref(false)
    const addCertDoctor = () => {
      loading.value = true
      check.checkAddCertDoctor({
        opd_id: props.opdId,
        cert_comment: cert_comment.value,
      }).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        loading.value = false
        emit('onAdd', res.data)
        emit('update:isAddCertDoctor', false)
      })
    }

    watch(() => props.isAddCertDoctor, val => {
      if (val) {
        cert_comment.value = 'สุขภาพสมบูรณ์แข็งแรงดี'
      }
    })

    return {
      cert_comment,
      loading,
      addCertDoctor,
    }
  },
}
</script>
