<template>
  <div>
    <v-card
      min-height='595'
      :loading='loading'
    >
      <v-card-title class='py-3'>
        {{ opdData.opd_code }}
        <span
          v-if="opdData.opd_status_id=='0'"
          class='error--text ml-2'
        >({{ $t('cancel') }})</span>
        <span class='error--text text-sm pt-1 ml-2'>
          {{ $t('upload_up_to_9_images') }}
        </span>
        <v-spacer></v-spacer>
        <v-btn
          color='primary'
          :loading='uploadLoading'

          :disabled="filebeforeList.length >=9||uploadLoading ||opdData.opd_status_id=='0'"
          class='d-none d-sm-block'
          @click='$refs.refInputEl.click()'
        >
          <span class='d-none d-sm-block'>   {{ $t('upload_pictures') }}</span>
        </v-btn>
        <v-btn
          color='primary'
          :loading='uploadLoading'
          :disabled="filebeforeList.length >=9||uploadLoading||opdData.opd_status_id=='0'"
          class='d-block d-sm-none'
          icon
          small
          @click='$refs.refInputEl.click()'
        >
          <v-icon>{{ icons.mdiCloudUploadOutline }}</v-icon>
        </v-btn>
        <input
          ref='refInputEl'
          type='file'
          multiple
          accept='.jpeg,.png,.jpg,GIF'
          :hidden='true'
          @change='uploadFiles'
        />
      </v-card-title>
      <v-divider class='mt-0'></v-divider>
      <v-card-text>
        <div
          v-if='!filebeforeList.length'
          align='center'
        >
          <p>
            {{ $t('no_information') }}
          </p>
        </div>
        <draggable
          v-if="opdData.opd_status_id != '0'"
          v-model='filebeforeList'
          handle='.handle'
          class='row mb-2'
          @end='updateSort'
        >
          <v-col
            v-for='(item, index) in filebeforeList'
            :key='index'
            cols='12'
            sm='6'
            md='3'
            lg='2'
          >
            <div align='center'>
              <v-card>
                <v-img
                  height='200'
                  class='elevation-2 handle'
                  style='cursor:all-scroll'
                  :src='item.url'
                >
                </v-img>
                <v-card-actions class='mt-5'>
                  <v-spacer></v-spacer>
                  <v-tooltip
                    color='#212121'
                    bottom
                  >
                    <template v-slot:activator='{ on, attrs }'>
                      <v-btn
                        color='warning'
                        v-bind='attrs'
                        small
                        class='mx-2'
                        outlined
                        icon
                        v-on='on'
                        @click='downloadImage(item)'
                      >
                        <v-icon
                          small
                        >
                          {{ icons.mdiCloudDownloadOutline }}
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>{{
                        $t('download')
                      }}</span>
                  </v-tooltip>
                  <v-tooltip
                    color='#212121'
                    bottom
                  >
                    <template v-slot:activator='{ on, attrs }'>
                      <v-btn
                        color='primary'
                        v-bind='attrs'
                        small
                        class='mx-2'
                        icon
                        outlined
                        v-on='on'
                        @click='previewImage(item)'
                      >
                        <v-icon small>
                          {{ icons.mdiImageSearchOutline }}
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>{{
                        $t('detail')
                      }}</span>
                  </v-tooltip>
                  <v-tooltip
                    color='#212121'
                    bottom
                  >
                    <template v-slot:activator='{ on, attrs }'>
                      <v-btn
                        color='error'
                        v-bind='attrs'
                        class='mx-2'
                        small
                        icon
                        outlined
                        v-on='on'
                        @click='isUserConfirm = true ; fileDelete = item'
                      >
                        <v-icon small>
                          {{ icons.mdiDeleteOutline }}
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>{{
                        $t('delete')
                      }}</span>
                  </v-tooltip>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </div>
          </v-col>
        </draggable>
      </v-card-text>
    </v-card>
    <v-dialog v-model='isShowImage'>
      <v-img
        :src='urlDetailImage'
        contain
      >
      </v-img>
    </v-dialog>
    <UserConfirm
      v-model='isUserConfirm'
      @onSend='imageBoforeRemove'
    />
  </div>
</template>

<script>
import {
  ref, watch, onMounted,
} from '@vue/composition-api'
import draggable from 'vuedraggable'
import {
  mdiCloudDownloadOutline,
  mdiDeleteOutline,
  mdiEyeOutline,
  mdiImageSearchOutline,
  mdiCloudUploadOutline,
} from '@mdi/js'
import checkDetail from '@/api/queue/checkDetail'
import store from '@/store'
import UserConfirm from '@/components/basicComponents/UserConfirm.vue'

export default {
  components: {
    draggable,
    UserConfirm,
  },
  props: {
    opdData: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const filebeforeList = ref([])
    const files = ref(null)
    const fileDelete = ref(null)
    const urlDetailImage = ref('')
    const isShowImage = ref(false)
    const uploadLoading = ref(false)
    const loading = ref(false)
    const s3Config = require('@/plugins/s3Confing')
    const s3 = s3Config()
    const formUpload = ref(null)
    const isUserConfirm = ref(false)
    const refInputEl = ref(null)

    const pathS3 = store.state.path_s3
    const {
      imageBeforeList, imageBeforeSort, imageBeforeAdd, imageBeforeRemove,
    } = checkDetail
    const downloadImage = async file => {
      files.value = file
      const fileName = file.url.replace(pathS3, '')
      const params = {
        Bucket: 'apsth-assets-upload',
        Key: fileName,
      }
      const data = await s3.getObject(params).promise()
      const blob = new Blob([data.Body])

      // Usage
      downloadBlob(blob, `${new Date()}.${files.value.type == 'pdf' ? 'pdf' : 'png'}`)
    }

    const downloadBlob = (blob, name) => {
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        return window.navigator.msSaveOrOpenBlob(blob)
      }

      // For other browsers:
      // Create a link pointing to the ObjectURL containing the blob.
      const data = window.URL.createObjectURL(blob)

      const link = document.createElement('a')
      link.href = data
      link.download = name

      // this is necessary as link.click() does not work on the latest firefox
      link.dispatchEvent(
        new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          view: window,
        }),
      )

      setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data)
        link.remove()
      }, 100)
    }

    const uploadFiles = fileList => {
      uploadLoading.value = true
      const formData = new FormData()
      formData.append('opd_id', props.opdData.opd_id)
      formData.append('type', 1)
      let i = 0
      for (const item of fileList.target.files) {
        formData.append(`image[${i}]`, item)
        i++
      }

      imageBeforeAdd(formData).then(res => {
        store.commit('app/ALERT', {
          message: res.response ? 'add_data_successfully' : res.message,
          color: res.response ? 'success' : 'error',
        })
        getImageBefore()
        uploadLoading.value = false
        formUpload.value.reset()
        refInputEl.value = null
      })
    }

    onMounted(() => {
      getImageBefore()
    })

    const getImageBefore = () => {
      loading.value = true
      imageBeforeList({
        opd_id: props.opdData.opd_id,
      }).then(res => {
        filebeforeList.value = res.map(element => ({
          name: element.opdupload_id,
          url: element.opdupload_image,
        }))
      })
      loading.value = false
    }

    const imageBoforeRemove = () => {
      loading.value = true
      imageBeforeRemove(`check/imagefile/${props.opdData.opd_id}`, fileDelete.value.name).then(res => {
        store.commit('app/ALERT', {
          message: res.response ? 'delete_data_successfully' : res.message,
          color: res.response ? 'success' : 'error',
        })
        isUserConfirm.value = false
        getImageBefore()
        formUpload.value.reset()
        refInputEl.value = null
      })
    }

    const previewImage = file => {
      urlDetailImage.value = file.url
      isShowImage.value = true
    }

    const updateSort = () => {
      const listImage = filebeforeList.value.map(item => item.name)
      const body = {
        opdupload_id: listImage,
      }
      imageBeforeSort(body).then(res => {
        store.commit('app/ALERT', {
          message: res.response ? 'update_data_successfully' : 'error',
          color: res.response ? 'success' : 'error',
        })
        getImageBefore()
      })
    }

    watch(() => props.opdData, () => {
      getImageBefore()
    })

    return {
      formUpload,
      refInputEl,
      urlDetailImage,
      fileDelete,
      filebeforeList,
      updateSort,
      downloadImage,
      uploadFiles,
      loading,
      imageBoforeRemove,
      uploadLoading,
      isUserConfirm,
      isShowImage,
      previewImage,
      icons: {
        mdiCloudDownloadOutline,
        mdiDeleteOutline,
        mdiEyeOutline,
        mdiImageSearchOutline,
        mdiCloudUploadOutline,
      },
    }
  },
}
</script>
