<template>
  <div>
    <v-dialog v-model="isCancel" persistent max-width="500">
      <v-card>
        <v-card-title>
          {{ $t('confirm_cancellation') }}
        </v-card-title>
        <v-form ref="formCancel" @submit.prevent="confirmCancel">
          <v-card-text>
            <v-textarea
              v-model="comment"
              :label="$t('note_of_cancellation')"
              :rules="[required]"
              outlined
              dense
            ></v-textarea>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" type="submit" :loading="loading" :disabled="loading">
              {{ $t('confirm') }}
            </v-btn>
            <v-btn color="secondary " outlined @click="$emit('update:is-cancel', false)">
              {{ $t('cancel') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { required } from '@/@core/utils/validation'
import cancel from '@/api/cancel'
import store from '@/store'
import router from '@/router'

export default {
  model: {
    prop: 'isCancel',
    event: 'update:is-cancel',
  },
  props: {
    isCancel: {
      type: Boolean,
      default: false,
    },
    path: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const comment = ref('')
    const formCancel = ref(null)
    const loading = ref(false)

    const { onCancel } = cancel
    const confirmCancel = () => {
      const isFormValid = formCancel.value.validate()
      if (!isFormValid) return
      loading.value = true
      let body = {}
      if (props.path == 'check/status') {
        body = {
          opd_id: props.id,
          opd_comment: comment.value,
        }
      }
      onCancel(props.path, body).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        emit('update:is-cancel', false)
        store.commit('app/setStatusUpdateHeader')
        loading.value = false
        if (res.response) {
          emit('onUpdate')
        }
        if (router.history.current.path == '/checking') {
          router.push({ name: 'Examination-Room' })
        }
      })
    }

    watch(
      () => props.isCancel,
      value => {
        if (value) {
          comment.value = ''
        }
      },
    )

    return {
      loading,
      comment,
      formCancel,
      confirmCancel,
      required,
    }
  },
}
</script>
