<template>
  <div>
    <v-card-title>
      <span>{{ opdData.opd_code }}</span>
      <span
        v-if="opdData.opd_status_id=='0'"
        class="error--text ml-2"
      >({{ $t('cancel') }})</span>
      <v-spacer></v-spacer>
      {{ opdData.room_name }}
    </v-card-title>
    <v-data-table
      :headers="columns"
      :items="dataTableList"
      :options.sync="options"
      dense
      :loading="loading"
      fixed-header
      disable-sort
      hide-default-footer
      height="595"
      :loading-text="$t('data_loading_please_wait')"
      :no-data-text="loadingData?$t('data_loading_please_wait'):$t('no_information')"
    >
      <template v-slot:[`item.checklist`]="{ item }">
        <ol>
          <li
            v-for="(i, index) in item.checklist"
            :key="index"
          >
            {{ i.checklist_name }}
          </li>
        </ol>
      </template>
      <template v-slot:[`item.opdchecking_discount`]="{ item }">
        {{ item.opdchecking_discount | formatPrice }}
      </template>
      <template v-slot:[`item.opdchecking_cost`]="{ item }">
        {{ item.opdchecking_cost | formatPrice }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { ref, onMounted, watch } from '@vue/composition-api'
import { sumdate, formatPrice } from '@/plugins/filters'
import { i18n } from '@/plugins/i18n'

export default {

  filters: {
    sumdate,
    formatPrice,
  },
  props: {

    loadingData: {
      type: Boolean,
      default: false,
    },
    tableList: {
      type: Array,
      default: () => [],
    },
    opdData: {
      type: Object,
      default: () => {},
    },
  },

  setup(props) {
    const loading = ref(true)
    const check_list = ref([])

    const dataTableList = ref([])
    const options = ref({})
    const columns = ref([
      {
        text: i18n.t('code'), align: 'start', value: 'opdchecking_id', width: 50,
      },
      { text: i18n.t('examination_list'), value: 'opdchecking_name', width: 200 },
      { text: i18n.t('examination_detail'), value: 'checklist', width: 200 },
      {
        text: i18n.t('doctor_fee'), value: 'opdchecking_fee', width: 200, align: 'end',
      },
      {
        text: i18n.t('examination_fee'), value: 'opdchecking_cost', width: 200, align: 'end',
      },
      {
        text: `${i18n.t('discount')} (${i18n.t('examination_fee')})`, value: 'opdchecking_discount', align: 'end', width: 200,
      },
      {
        text: `${i18n.t('total')} (${i18n.t('examination_fee')})`, value: 'opdchecking_cost', width: 200, align: 'end',
      },
    ])

    onMounted(() => {
      dataTableList.value = props.tableList
      options.value.page = 1
      options.value.itemsPerPage = -1

      loading.value = false
    })

    watch(() => props.tableList, value => {
      dataTableList.value = JSON.parse(JSON.stringify(value))
      options.value.page = 1
      options.value.itemsPerPage = -1
      loading.value = false
    })

    return {
      loading,
      check_list,
      dataTableList,
      columns,
      options,
    }
  },

}
</script>
